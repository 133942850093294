<template>
  <div v-if="group.role" class="container">
    <GroupRequest v-if="group.type === 'Private'
      && (group.role === 'guest' || group.role === 'request')"
      :group="group" />
    <GroupHidden v-else-if="group.role === 'hidden'" :group="group" />
    <div v-else id="user_cabinet_header" class="user-cabinet__inner">
      <div class="user-cabinet__area">
        <Alert :variant="variant" v-if="text">{{ text }}</Alert>
        <div class="user-cabinet__area-top">
          <router-link v-if="false && role === 'request' || role === 'guest'"
            class="btn btn-primary"
            :to="{ name: 'profileGroupsView', params: { id: $route.params.id } }"
          >
            {{role === 'request' ? 'Undo Request' : 'Request Membership'}}
          </router-link>
          <router-link v-else-if="role === 'admin' || role === 'owner'"
            class="btn btn-primary"
            :to="{ name: 'groupStrategyProjectCreate', params: { group_id: group.id } }"
          >
            {{buttonName}}
          </router-link>
        </div>
        <div v-if="projects.length" class="project__list">
          <ContainerCardStrategyProject
            v-for="project in projects"
            :key="project.id + project.name"
            :project="project"
            :isGroup="true"
            :unreadCommentsCount="unreadProjects.find((pr) => pr.id === project.id)"
          />
<!--          <Pagination v-if="pagination.pageCount > 1"-->
<!--            :pagination="pagination"-->
<!--            v-on:changepage="handlePagination"-->
<!--          />-->
        </div>
      </div>
      <Modal v-if="group.role !== 'guest' && group.role !== 'request'"
        @close="hideModal('deleteModal')"
        id="deleteModal"
        title="Are you sure?"
        :buttonTextOk="modal.btnCaption"
        :buttonVariantOk="modal.variant"
        :showButtonCancel="true"
        @cancel="hideModal('deleteModal')"
        @ok="onDeleteProject"
      />
    </div>
  </div>
</template>
<script>
import ContainerCardStrategyProject from '@/views/profile/Components/ContainerCardStrategyProject.vue';
import GroupRequest from '@/views/profile/MyGroups/Group/Components/Feed/GroupRequest.vue';
import GroupHidden from '@/views/profile/MyGroups/Group/Components/Feed/GroupHidden.vue';
// import Pagination from '@/views/profile/MyGroups/Pagination.vue';

import Modal from '@/components/Modal.vue';
import Alert from '@/components/Alert.vue';

import modal from '@/mixins/modal';
import pagination from '@/mixins/pagination';
import exception from '@/mixins/exception';
import alert from '@/mixins/alert';
import scroll from '@/mixins/scroll';
import content from '@/mixins/content';
import websocket from '@/mixins/websocket';

import GroupService from '@/services/GroupService';
import ProjectService from '@/services/ProjectService';

export default {
  name: 'ProfileGroupFeed',
  components: {
    ContainerCardStrategyProject,
    GroupRequest,
    // Pagination,
    Alert,
    Modal,
    GroupHidden,
  },
  mixins: [pagination, exception, alert, modal, scroll, content, websocket],
  props: {
    role: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      projects: [],
      group: {},
      projectIdDelete: undefined,
      projectParams: {
        page: 1,
        perPage: 5,
      },
      totalPages: 1,
      // pagination: {
      //   pageCurrent: 1,
      //   pageCount: 1,
      //   perPage: 5,
      //   total: 0,
      // },
      modal: {
        id: 'deleteModal',
        variant: 'danger',
        btnCaption: 'Delete',
        userID: 0,
        type: '',
        action: '',
      },
    };
  },
  async created() {
    this.group = await this.getGroup();
    this.getProjects();

    this.subscribeToChannel('group-feed', this.setUnreadGroupFeed, this.group.id);
    this.$eventBus.$on('deleteProject', this.onShowModal);
    this.$eventBus.$on('addLike', this.onAddLike);
    this.$eventBus.$on('removeLike', this.onRemoveLike);
  },
  mounted() {
    window.addEventListener('scroll', async () => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;
      const footer = document.getElementsByClassName('footer');
      if (window.innerWidth <= 768
        && scrollTop + clientHeight >= scrollHeight - footer[0].scrollHeight) {
        await this.getProjects();
      }
      if (window.innerWidth > 768 && scrollTop + clientHeight >= scrollHeight) {
        await this.getProjects();
      }
    });
  },
  computed: {
    me() {
      return this.$store.getters.getMe;
    },
    unreadProjects() {
      return this.$store.getters.getUnreadGroupProjects || [];
    },
    buttonName() {
      if (this.group.style_name === 'Showrooms') return 'New Showroom';
      if (this.group.style_name === 'Learning Platform') return 'New Course or New Training';

      return 'New Strategy Project';
    },
  },
  methods: {
    async getGroup() {
      let res = null;

      try {
        res = await GroupService.group(this.$route.params.id);
        const group = res.data.id ? res.data : this.group;
        this.$emit('setGroup', group);
        return group;
      } catch (e) {
        this.handleException(e);
        return this.group;
      }
    },
    async getProjects() {
      let res = null;

      if (this.projectParams.page <= this.totalPages) {
        try {
          res = await GroupService.getProjectsGroup(this.$route.params.id, {
            page: this.projectParams.page,
            'per-page': this.projectParams.perPage,
          });
          this.projects.push(...res.data);
          this.totalPages = res.headers['x-pagination-page-count'];
          this.projectParams.page = +res.headers['x-pagination-current-page'] + 1;
          // this.setPagination(res.headers);
          // this.projects = res.data;
        } catch (e) {
          // this.projects = [];
          // this.showAndHideAlertSuccess(res.response.data.message, 'danger');
          this.handleException(e);
        }
      }
    },
    handlePagination() {
      this.getProjects();
    },
    onShowModal(id) {
      this.projectIdDelete = id;
      this.showModal(this.modal.id);
    },
    onDeleteProject() {
      this.projects = [];
      this.totalPages = 1;
      this.projectParams.page = 1;
      this.deleteProject();
    },
    async deleteProject() {
      let res = null;

      try {
        res = await ProjectService.delete(this.projectIdDelete);
        this.hideModal(this.modal.id);
        this.getProjects();
        this.scrollToTop('user_cabinet_header');
        this.showAndHideAlertSuccess(res.data.message, 'success');
      } catch (e) {
        this.hideModal(this.modal.id);
        this.scrollToTop('user_cabinet_header');
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        this.handleException(e);
      }
    },
    async onAddLike(projectID) {
      try {
        await ProjectService.addLike(projectID);

        const curProject = this.projects.find((project) => project.id === projectID);
        curProject.like = true;
        curProject.cnt_like += 1;
      } catch (e) {
        console.log(e.response);
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        this.handleException(e);
      }
    },
    async onRemoveLike(projectID) {
      try {
        await ProjectService.removeLike(projectID);

        const curProject = this.projects.find((project) => project.id === projectID);
        curProject.like = false;
        curProject.cnt_like -= 1;
      } catch (e) {
        console.log(e.response);
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        this.handleException(e);
      }
    },
    setUnreadGroupFeed(event) {
      const data = JSON.parse(event.data).message;
      this.$eventBus.$emit('setFeedsCount', Object.keys(data.feed).length, 'feeds');
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('deleteProject', this.onShowModal);
    this.$eventBus.$off('addLike', this.onAddLike);
    this.$eventBus.$off('removeLike', this.onRemoveLike);
    window.removeEventListener('scroll', async () => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;
      const footer = document.getElementsByClassName('footer');
      if (window.innerWidth <= 768
        && scrollTop + clientHeight >= scrollHeight - footer[0].scrollHeight) {
        await this.getProjects();
      }
      if (window.innerWidth > 768 && scrollTop + clientHeight >= scrollHeight) {
        await this.getProjects();
      }
    });
  },
};
</script>
